import * as styles from './CardsVisualization.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import { sortBy } from 'lodash'

import { useCSV } from 'utils/useCSV'

import { TextButton } from 'components/controls'
import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer'

import { Card } from './data'

import Controls from './Controls'
import Table from './Table'

const minimumInitialTesters = 0.01

interface Props {
  sets: string[]
  cardsData: string
}

export const CardsVisualization: React.FC<Props> = (props) => {
  const { sets, cardsData } = props

  const cards = useCSV<Card>(cardsData)

  const [selectedSets, setSelectedSets] = useState<Record<string, boolean>>(
    sets.reduce((result, set) => {
      result[set] = true
      return result
    }, {} as Record<string, boolean>),
  )

  const [rowLimit, setRowLimit] = useState(30)

  const filteredRows = useMemo(
    () =>
      cards?.filter(
        (card) =>
          card.testerFraction >= minimumInitialTesters &&
          selectedSets[card.set],
      ) ?? [],
    [cards, selectedSets],
  )

  const [sort, setSort] = useState<{ column: keyof Card; ascending: boolean }>({
    column: 'testerFraction',
    ascending: false,
  })

  const sortedCards = useMemo(() => {
    const sorted = sortBy(filteredRows, (r) => r[sort.column])
    return sort.ascending ? sorted : sorted.reverse()
  }, [filteredRows, sort])

  const truncatedCards = useMemo(
    () => sortedCards.slice(0, rowLimit),
    [sortedCards, rowLimit],
  )

  const addRows = useCallback(() => {
    setRowLimit((value) => value + 50)
  }, [])

  return (
    <div className={styles.container}>
      <Controls selectedSets={selectedSets} setSelectedSets={setSelectedSets} />

      <HorizontalScrollingContainer noFitWidth>
        <Table cards={truncatedCards} sort={sort} setSort={setSort as any} />
      </HorizontalScrollingContainer>

      {truncatedCards.length === 0 && (
        <div className={styles.note}>Select a set to view cards.</div>
      )}

      {rowLimit < filteredRows.length && (
        <div className={styles.note}>
          Showing {rowLimit} of {filteredRows.length} cards.{' '}
          <TextButton type="button" onClick={addRows}>
            Show More
          </TextButton>
        </div>
      )}
    </div>
  )
}
