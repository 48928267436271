import * as styles from './ControlGroup.module.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

export const ControlGroup: React.FC<Props> = (props) => {
  const { children } = props

  return <div className={styles.container}>{children}</div>
}
