import * as styles from './Table.module.scss'

import React from 'react'

import { Card } from './data'

import TableHeading from './TableHeading'
import TableRow from './TableRow'

interface Props {
  cards: Card[]
  sort: { column: string; ascending: boolean }
  setSort(sort: { column: string; ascending: boolean }): void
}

const Table: React.FC<Props> = (props) => {
  const { cards, sort, setSort } = props

  const onClick = (column: string) => {
    setSort({
      column,
      ascending: sort.column === column ? !sort.ascending : column === 'name',
    })
  }

  const headingProps = {
    sort,
    onClick,
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.head}>
          <tr className={styles.headingRow}>
            <TableHeading name="changeRelativeToRespondents" borderless>
              <div className={styles.legend}>
                <button
                  type="button"
                  title="Percent change over time of survey respondents including a card"
                  className={styles.legendItem}
                  onClick={() => onClick('changeRelativeToRespondents')}
                >
                  <div
                    className={styles.legendIcon}
                    style={{ backgroundColor: '#2d739d' }}
                  />
                  Surveyed
                  {sort && (
                    <span className={styles.sortIcon}>
                      {sort.column === 'changeRelativeToRespondents' ? (
                        sort.ascending ? (
                          '▲'
                        ) : (
                          '▼'
                        )
                      ) : (
                        <>&nbsp;</>
                      )}
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  title="Difference between Lucky Paper survey play rates compared to all Cube Cobra cubes today"
                  className={styles.legendItem}
                  onClick={() => onClick('changeRelativeToAllCubeCobra')}
                >
                  <div
                    className={styles.legendIcon}
                    style={{ backgroundColor: '#5CABDC' }}
                  />
                  Cube Cobra
                  {sort && (
                    <span className={styles.sortIcon}>
                      {sort.column === 'changeRelativeToAllCubeCobra' ? (
                        sort.ascending ? (
                          '▲'
                        ) : (
                          '▼'
                        )
                      ) : (
                        <>&nbsp;</>
                      )}
                    </span>
                  )}
                </button>
              </div>
            </TableHeading>

            <TableHeading name="name" title="Name of card" {...headingProps}>
              Card
            </TableHeading>
            <TableHeading
              name="averageRating"
              title="Average rating of this card in survey responses"
              {...headingProps}
              center
            >
              Avg. Rating
            </TableHeading>
            <TableHeading
              name="testerFraction"
              title="Fraction of survey responses including this card"
              {...headingProps}
              center
            >
              Testers
            </TableHeading>
            <TableHeading
              name="ccCubes"
              title="Current number of Cubes with this card on Cube Cobra"
              {...headingProps}
              center
            >
              Cubes
            </TableHeading>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.spacer}></tr>
          {cards.map((card) => (
            <TableRow key={`${card.name}-${card.set}`} card={card} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
