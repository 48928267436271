import * as styles from './SetStatistics.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import CardHover from 'components/cards/CardHover'

import { Histogram } from './Histogram'
import { Set } from './types'

interface Props {
  set: Set
  maxCardPlayCount?: number
}

export const SetStatisticsUnmemoized: React.FC<Props> = (props) => {
  const { set, maxCardPlayCount } = props

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <img className={styles.symbol} src={set.iconURL} alt="" />

        <div className={styles.name}>{set.name}</div>
      </div>

      {set.cardPlayCounts && (
        <Histogram data={set.cardPlayCounts} maxX={maxCardPlayCount} />
      )}

      <div className={styles.group}>
        <div className={styles.secondaryHeading}>Status at Rotation</div>

        {set.mostOverrated && (
          <div className={styles.statistic}>
            Most overvalued:{' '}
            <strong>
              <CardHover card={set.mostOverrated} set={set.code}>
                {set.mostOverrated}
              </CardHover>
            </strong>
          </div>
        )}

        {set.mostUnderrated && (
          <div className={styles.statistic}>
            Most overlooked:{' '}
            <strong>
              <CardHover card={set.mostUnderrated} set={set.code}>
                {set.mostUnderrated}
              </CardHover>
            </strong>
          </div>
        )}

        {set.mostPlayedCubeCobra && (
          <div className={styles.statistic}>
            Most played on Cube Cobra:{' '}
            <strong>
              <CardHover card={set.mostPlayedCubeCobra} set={set.code}>
                {set.mostPlayedCubeCobra}
              </CardHover>
            </strong>
          </div>
        )}
      </div>

      <div className={styles.group}>
        <div className={styles.secondaryHeading}>
          Prospective Survey Summary
        </div>

        <div className={styles.statistic}>
          Submissions: <strong>{set.submissions}</strong>
        </div>

        {set.medianCards && (
          <div className={styles.statistic}>
            Median cards tested: <strong>{set.medianCards}</strong>
          </div>
        )}

        <div className={styles.statistic}>
          Average rating given: <strong>{set.averageRating.toFixed(2)}</strong>
        </div>

        {set.popularCardName != null &&
          set.popularCardPercentage != null &&
          set.popularCardRating != null && (
            <div className={styles.statistic}>
              Most tested
              {set.topCardName === set.popularCardName && (
                <> and top rated </>
              )}{' '}
              card:{' '}
              <strong>
                <CardHover card={set.popularCardName} set={set.code}>
                  {set.popularCardName}
                </CardHover>
              </strong>
              , included in{' '}
              <strong>
                {(set.popularCardPercentage * 100).toFixed(0).toString()}
              </strong>
              <strong>%</strong> of submissions with an average rating of{' '}
              <strong>{set.popularCardRating.toFixed(2)}</strong>
            </div>
          )}

        {set.topCardName &&
          set.topCardPercentage != null &&
          set.topCardRating != null &&
          set.topCardName !== set.popularCardName && (
            <div className={styles.statistic}>
              Top rated card:{' '}
              <strong>
                <CardHover card={set.topCardName} set={set.code}>
                  {set.topCardName}
                </CardHover>
              </strong>
              , included in{' '}
              <strong>
                {(set.topCardPercentage * 100).toFixed(0).toString()}
              </strong>
              <strong>%</strong> of submissions with an average rating of{' '}
              <strong>{set.topCardRating.toFixed(2)}</strong>
            </div>
          )}
      </div>

      <Link className={styles.link} key={set.code} to={set.path}>
        View Full Survey Results &rarr;
      </Link>
    </div>
  )
}

export const SetStatistics = React.memo(SetStatisticsUnmemoized)
