import * as styles from './TableRow.module.scss'

import React from 'react'
import classNames from 'classnames'

import CardHover from 'components/cards/CardHover'

import { Card } from './data'

import HistogramRow from './HistogramRow'

interface Props {
  card: Card
}

function label(value: number, group: string) {
  return `${(Math.abs(value) * 100).toFixed(1)}% ${
    value < 0 ? 'decrease' : 'increase'
  } in play among ${group}`
}

const TableRow: React.FC<Props> = (props) => {
  const { card } = props

  return (
    <tr className={styles.container}>
      <td>
        <HistogramRow
          values={[
            {
              value: card.changeRelativeToRespondents,
              label: label(card.changeRelativeToRespondents, 'surveyed'),
            },
            {
              value: card.changeRelativeToAllCubeCobra,
              label: label(card.changeRelativeToAllCubeCobra, 'all Cube Cobra'),
            },
          ]}
          min={-1}
          max={0.5}
        />
      </td>
      <td className={styles.cell}>
        <CardHover card={card.name} set={card.set}>
          {card.name}{' '}
          <span className={styles.set}>- {card.set.toUpperCase()}</span>
        </CardHover>
      </td>
      <td className={classNames(styles.cell, styles.center)}>
        {card.averageRating.toFixed(1)}
      </td>
      <td className={classNames(styles.cell, styles.center)}>
        {(card.testerFraction * 100).toFixed(1)}%
      </td>
      <td className={classNames(styles.cell, styles.center)}>
        <a
          href={`https://cubecobra.com/tool/card/${card.name}`}
          title={`View ${card.name} on Cube Cobra`}
        >
          {card.ccCubes}
        </a>
      </td>
    </tr>
  )
}

export default React.memo(TableRow)
