import * as styles from './Select.module.scss'

import React from 'react'

interface Props {
  value: string
  options: { name: string; label: string; disabled?: boolean }[]
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void
  name?: string
}

export const Select: React.FC<Props> = (props) => {
  const { value, options, onChange, name } = props

  return (
    <div className={styles.container}>
      <select
        className={styles.select}
        value={value}
        onChange={onChange}
        name={name}
      >
        {options.map((option, index) => (
          <option
            key={`${index}${option.name}`}
            value={option.name}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>

      <div className={styles.icon}>▼</div>
    </div>
  )
}
